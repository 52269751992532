/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'gatsby';
import { Layout, SEO } from '@/components';

export const Head = ({ location }) => {
  const title = 'Linking the African Crossroads Network';
  const description = 'Njano is a cloud-based flexible office management solution designed to manage workplaces';
  return <SEO title={title} description={description} location={location} />;
};

const ArticlePage = () => (
  <Layout dark logo>
    <div className="md:max-w-5xl container w-full px-16 pt-20 mx-auto bg-white shadow-lg">
      <div className="md:px-6 w-full px-4 text-xl leading-normal text-gray-800">
        <div className="font-content">
          <span className="text-primary md:text-sm space-y-8 text-base font-bold">
            <Link to="/" className="bg-secondary md:text-sm px-6 py-2 text-base font-bold text-white no-underline">
              &#8592; BACK TO HOME
            </Link>
            <h1 className="font-heading md:text-5xl pt-6 pb-2 text-3xl font-bold text-gray-900 break-normal">
              Njano, Linking the African Crossroads Network
            </h1>
          </span>
        </div>
        <p className="py-6">
          Njano is a cloud-based flexible office management solution designed to manage workplaces. It offers an optimal
          solution to link initiatives, people and communities. The platform is user-friendly and consists of a number
          of tools and features that are really helpful and playful. In the current situation of remote working and
          online events, the use of a tool that facilitates interactions between people and communities seems not only
          adequate, but much needed. This is why the African Crossroads network, which has shifted some of its
          activities from offline to online, has adopted the Njano platform for its members.
        </p>

        <p className="py-6">
          <h4 className="font-heading mb-4 font-bold">Creating Njano</h4>
          The platform has been created and developed with the support of Hivos. The beta version of Njano was launched
          at the African Crossroads event in Mombasa, Kenya, in November 2019. Since then, it has been refined and
          redefined to become what it is now: a platform that allows the African Crossroads community to connect,
          interact and communicate, which is the essence of the AC network itself. Some of the members of the African
          Crossroads network have already joined the platform and are actively using it. They have created circles to
          chat with one another, and are using Njano to be up to date with the monthly gatherings. Circles are spaces
          for people with similar interests in which members can share about certain topics or subjects. For instance,
          if your field of interest is art and culture, and you want to learn about new opportunities and initiatives,
          you can create a circle for people with the same interests to further discuss the subject.
        </p>
        <p className="py-6">
          <h4 className="font-heading mb-4 font-bold">
            Knowledge Exchange, at the heart of Njano and the African Crossroads Network
          </h4>
          Knowledge exchange is at the core of African Crossroads, as well as collaborations which enable Crossroaders
          to discuss the future of Africa from different perspectives and regions. Njano is in constant improvement in
          terms of new functionalities and better user-experience. The team is working hard to give the best version of
          the platform while constantly reviewing the feedback from its users. New windows have been opened for the
          African Crossroads network by using a platform that answers most of its needs in these challenging times. With
          its Community and Events features, Njano is able to support the network which has shifted all its activities
          online since the beginning of the year. These features facilitate the unfolding of African Crossroads
          gatherings and exchanges, and represent a long-term investment for the network. Njano is thus the ideal
          platform to host diversity and gatherings thanks to its many tools. We are confident that the community which
          is avid of interaction and communication will find in the Njano platform a longtime companion.
        </p>
      </div>
    </div>
  </Layout>
);

export default ArticlePage;
